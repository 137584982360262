<template>
  <div
    class="form-builder__input"
    :class="schema.styleClasses || ''"
  >
    <q-toggle
      class="full-width"
      :model-value="(schema.get && schema.get(schema.value)) || schema.value"
      :label="`${schema.label} ${schema.required ? '*' : ''}`"
      :disable="!!schema.disabled"
      :required="!!schema.required"
      :standout="!!schema.standout"
      :placeholder="schema.placeholder || ''"
      :hide-bottom-space="!!schema.hint"
      :dense="!!schema.dense"
      @update:model-value="handleInput"
    />

    <div
      v-if="schema.hint"
      class="text-caption q-px-md"
    >
      {{ schema.hint }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleInput (e) {
      typeof this.schema.onChange === 'function' && this.schema.onChange(e)
    }
  }
}
</script>
